<template>
  <div>
    <div
      class="search-container"
      style="margin: 0 auto; width: 50%; margin-top: -4px"
    >
      <div class="row justify-content-between position-relative">
        <div class="col pl-4">
          <input
            type="text"
            class="col"
            style="width: 100%"
            placeholder="请输入公司名称、股票代码"
            id="inputText"
            @click.stop
            @keydown.down="keyDownChoose"
            @keydown.up="keyUpChoose"
            v-model="searchText"
            @keyup="queryCompany"
            @focus="inputFous"
          />
        </div>
        <div
          class="cursor_pionter search_s color-4d justify-content-evenly"
          style="
            line-height: 52px;
            width: 154px;
            height: 49px;
            background-image: linear-gradient(90deg, #3c9bfe 7%, #3a85fd 100%);
            border-radius: 0px 26px 26px 0px;
            padding-left: 20px;
          "
          @click.stop="searchCompany"
        >
          <i style="color: #ffffff" class="el-icon-search"></i>
          <span
            style="
              width: 48px;
              height: 24px;
              font-family: PingFangSC-Medium;
              font-size: 18px;
              color: #ffffff;
              text-align: center;
              line-height: 24px;
              font-weight: 500;
              padding-left: 20px;
            "
            >搜 索</span
          >
        </div>
        <div
          class="position-absolute search-list"
          @click.stop=""
          v-show="searchText != '' && searchGood"
        >
          <div v-if="data">
            <template v-for="(item, index) in searchLists">
              <a
                href="#"
                v-if="index < 8"
                :key="index"
                class="d-block mt-2 search-items"
                style="display: flex !important; align-items: center"
                :class="{ ch: index == keyDownNows }"
                @mouseover="mouseClearStyle"
                @click="stepToCompanyInfo(item)"
              >
                <span
                  class="color-4d search-hot-title d-inline-block text-center"
                  v-if="item.publish_type != '04'"
                  >股票</span
                >
                <span
                  class="color-4d search-hot-title d-inline-block text-center"
                  v-if="item.publish_type == '04'"
                  >债券</span
                >
                <span
                  class="color-4d ml-4 fz-14 text-center fontA"
                  style="width: 55px"
                  >{{ item.S_or_B_code }}</span
                >
                <span
                  class="color-4d ml-10 fz-14 text-center"
                  style="
                    max-width: 251px;
                    position: relative;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    white-space: nowrap;
                  "
                >
                  <!-- <span
                  v-if="item.is_financial"
                  class="
                    color-4d
                    search-hot-title
                    d-inline-block
                    fz-14
                    text-center
                  "
                  style="position: absolute; left: -79px"
                  >金融</span
                > -->
                  {{ item.companyInfo.name }}</span
                >
              </a>
            </template>
          </div>
          <div class="text-center" v-else>
            <span class="color-4d fz-24">未查询到</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="w-100 mt-5">
        <div class="fz-14 color-4d">
          关于<span class="color-le fz-16" style="padding: 0 4px">{{
            keyword
          }}</span
          >的搜索共<span>{{ total }}</span
          >条
        </div>
        <div class="bgc_w pt-10 pb-5 pl-4 pr-4">
          <div
            class="test pb-4 pt-3 justify-content-start"
            v-for="(company, key) in storeAll"
            :key="key"
          >
            <div class="col-md-10 col-sm-12">
              <!-- <div class="row align-items-center">
                <img
                  src="../../assets/img/common/more-hot.png"
                  :key="index"
                  v-for="(v, index) in hotArray"
                  class="mr-1"
                  alt=""
                />
                <img
                  src="../../assets/img/common/less-hot.png"
                  v-for="(v, index) in lessArray"
                  :key="index"
                  class="mr-1"  
                  alt=""
                />
                <span class="color-f70 fz-14 ml-2">热度</span>
              </div> -->
              <div class="row align-items-center">
                <span
                  class="row align-items-center fz-24 color-0"
                  @click="toCompanyDetail(company)"
                >
                  {{ company.companyInfo.name }}
                </span>
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '00'"
                  >主板</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '01'"
                  >创业板</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '02'"
                  >中小板</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '03'"
                  >新三板</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '05'"
                  >科创板</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '04'"
                  >债券</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2"
                  v-if="company.publish_type == '06'"
                  >北证</span
                >
                <span
                  class="fz-14 color-4ad pt-1 pb-1 pl-2 pr-2 bgc-df br_25 ml-2 mr-1"
                  v-if="company.companyInfo && company.companyInfo.is_financial"
                  >金融</span
                >
              </div>
              <span
                @click="toCompanyDetail(company)"
                class="fz-26 color-4ad cursor_pionter"
                >{{ company.S_or_B_name }}({{ company.S_or_B_code }})</span
              >
              <div class="row align-items-center color-4d mt-1 fz-14">
                <div class="" v-if="company.publish_type == '04'">
                  <span class="">债券代码:</span>
                  <span>{{ company.S_or_B_code }}</span>
                </div>
              </div>
              <div class="row align-items-center color-4d mt-1 fz-14">
                <div class="">
                  <span>统计局行业:</span>
                  <span v-if="company.companyInfo.CSRC_category != null">{{
                    company.companyInfo.CSRC_category.industry_name
                  }}</span>
                </div>
                <div class="ml-5">
                  <span class="">申万行业:</span>
                  <span v-if="company.companyInfo.SW_category != null">{{
                    company.companyInfo.SW_category.industry_name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center bottom-right">
            <v-pagination
              :total="total"
              @page-change="pageChange"
              @page-size-change="pageSizeChange"
              size="middle"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as sesstion from "../../store/localStorage";
import { mapGetters, mapState, mapActions } from "vuex";
import { yearList, tjINdustryList } from "@/api";
// import { qcc_CompanyInfo } from "@/api/companyDetail.js";
import page from "../page/page";
export default {
  components: {
    pagination: page,
  },
  data() {
    return {
      companyType: "0",
      searchLists: [],
      now: -1,
      searchText: "",
      searchGood: true,
      hotStand: 50,
      num: 0,
      array: [],
      lessArrays: [],
      isAdd: false,
      storeAll: [],
      total: 0, // 记录数据的总条数
      display: 10, // 每页显示数据的条数
      current: 1, // 当前的页数
      keyword: "",
      timer: null,
      data: true,
      SWAI: "",
      CSRC: "",
      pageSize: "",
      industryData: {},
    };
  },
  created() {
    $("body").click(function() {
      $(".search-list").css("display", "none");
    });
    this.$store.state.headLogo = true;
    // this.storeAll = this.$route.params.searchLists
    //this.$store.state.kw = this.keyword = this.$route.params.searchText;
    this.keyword = sesstion.getLocalData("searchText");
    this.queryCompanyList(1);
    //console.log(this.keyword)
  },

  methods: {
    ...mapActions([
      "queryPutData",
      "keyDownChoose",
      "keyUpChoose",
      "saveClickOrHoverIndex",
      "mouseClearStyle",
    ]),

    queryCompanyList(page) {
      // console.log(page);
      var value = this.keyword;
      if (this.checkNumber(value)) {
        this.$http
          .get(
            window.BASE_URL +
              "/company/listed/search-by-stock-paging/" +
              value +
              "/?page=" +
              page +
              "&page_size=" +
              this.pageSize
          )
          .then(
            (res) => {
              if (res.data.companyList.length == 0)
                this.$Message.warning("建议前往一般企业模块分析");
              this.storeAll = res.data.companyList;
              this.total = res.data.total;
            },
            (res) => {
              this.storeAll = [];
              this.total = 0;
              // console.log("请求失败！");
            }
          );
      } else {
        this.$http
          .get(
            window.BASE_URL +
              "/company/listed/search-by-name-paging/" +
              value +
              "/?page=" +
              page +
              "&page_size=" +
              this.pageSize
          )
          .then(
            (res) => {
              if (res.data.companyList.length == 0)
                this.$Message.warning("建议前往一般企业模块分析");
              this.storeAll = res.data.companyList;
              this.total = res.data.total;
            },
            (res) => {
              // console.log("请求失败！");
            }
          );
      }
    },
    pageChange(pageIndex) {
      // console.log(pageIndex);
      this.queryCompanyList(pageIndex);
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.queryCompanyList(this.current);
    },
    toCompanyDetail(company) {
      if (company.companyInfo && company.companyInfo.is_financial)
        return this.$Message.warning(
          "该企业是金融企业，请进入金融机构分析主题"
        );
      if (company.companyInfo.SWAI_code == "")
        return this.$Message.warning("该企业是退市企业，无法分析");

      let param = {
        company_code: company.company_code,
        report_time: "",
        report_type: "",
        report_year: "",
      };
      sesstion.setLocalData("rowList", param);
      yearList(param).then(({ data }) => {
        if (data.Status == 0) {
          this.$message.error(data.Message);
        } else if (data.Status == 1) {
          this.$confirm(data.Message, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sesstion.setLocalData("company", company);
              sessionStorage.back_name = this.$route.name;
              tjINdustryList({
                statistics_code:
                  this.industryData.companyInfo.statistics_code || "",
                industry_code: this.industryData.companyInfo.SWAI_code || "",
                CSRC_code: this.industryData.companyInfo.CSRC_code || "",
              }).then((res) => {
                // console.log(res);
                sesstion.setLocalData("industryData", res.data);
                // console.log(sesstion.getLocalData("industryData"));
              });
              // console.log(123);
              // qcc_CompanyInfo({
              //   company_name: this.industryData.companyInfo.name,
              // }).then(({ data }) => {
              //   sesstion.setLocalData("company_name", data);
              // });
              this.$router.push({ name: "CompanyDetail" });
            })
            .catch(() => {});
        } else {
          sesstion.setLocalData("company", company);
          sessionStorage.back_name = this.$route.name;
          this.industryData = sesstion.getLocalData("company");
          tjINdustryList({
            statistics_code:
              this.industryData.companyInfo.statistics_code || "",
            industry_code: this.industryData.companyInfo.SWAI_code || "",
            CSRC_code: this.industryData.companyInfo.CSRC_code || "",
          }).then((res) => {
            // console.log(res);
            sesstion.setLocalData("industryData", res.data);
            // console.log(sesstion.getLocalData("industryData"));
          });
          // qcc_CompanyInfo({
          //   company_name: this.industryData.companyInfo.name,
          // }).then(({ data }) => {
          //   // console.log(data);
          //   sesstion.setLocalData("company_name", data);
          // });
          this.$router.push({ name: "CompanyDetail" });
        }
      });
    },

    queryCompany() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.qc, 500);
    },
    qc() {
      var value = this.searchText;
      this.searchGood = true;
      if (value != "") {
        if (this.checkNumber(value)) {
          this.$http
            .get(
              window.BASE_URL + "/company/listed/search-by-stock/" + value + "/"
            )
            .then(
              (res) => {
                this.searchLists = [];
                this.searchLists = res.data;
              },
              (res) => {
                // console.log("请求失败！");
              }
            );
        } else {
          this.$http
            .get(
              window.BASE_URL + "/company/listed/search-by-name/" + value + "/"
            )
            .then(
              (res) => {
                //console.log("查询公司信息")
                if (res.data.errMessage == "没有查询到结果！") {
                  //console.log(1);
                  this.searchLists = [];
                  this.data = false;
                } else {
                  //console.log(2);
                  this.searchLists = res.data;
                  this.data = true;
                }
              },
              (res) => {
                // console.log("请求失败！");
              }
            );
        }
      }
    },
    inputFous() {
      var value = this.searchText;
      if (value != "") {
        $(".search-list").css("display", "block");
      }
    },
    stepToCompanyInfo(item) {
      if (item.is_financial)
        return this.$Message.warning(
          "该企业是金融企业，请进入金融机构分析主题"
        );
      if (item.companyInfo.SWAI_code == "")
        return this.$Message.warning("该企业是退市企业，无法分析");

      let param = {
        company_code: item.company_code,
        // menu_name: "simple" ,
        report_time: "",
        report_type: "",
        report_year: "",
      };
      sesstion.setLocalData("rowList", param);
      yearList(param).then(({ data }) => {
        if (data.Status == 0) {
          this.$message.error(data.Message);
        } else if (data.Status == 1) {
          this.$confirm(data.Message, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.searchGood = true;
              sesstion.setLocalData("company", item);
              this.$router.push({
                name: "CompanyDetail",
                query: { name: item.companyInfo.name },
              });
              sessionStorage.back_name = this.$route.name;
            })
            .catch(() => {});
        } else {
          this.searchGood = true;
          sesstion.setLocalData("company", item);
          this.$router.push({
            name: "CompanyDetail",
            query: { name: item.companyInfo.name },
          });
          sessionStorage.back_name = this.$route.name;
        }
      });
    },
    searchCompany() {
      var value = this.searchText;
      sesstion.setLocalData("searchText", value);
      this.keyword = value;
      this.searchGood = false;
      if (this.checkNumber(value)) {
        this.$http
          .get(
            window.BASE_URL +
              "/company/listed/search-by-stock-paging/" +
              value +
              "/?page=1"
          )
          .then(
            (res) => {
              if (res.data.companyList.length == 0)
                this.$Message.warning("建议前往一般企业模块分析");
              this.storeAll = res.data.companyList;
              this.total = res.data.total;
            },
            (res) => {
              this.storeAll = [];
              this.total = 0;
              // console.log("请求失败！");
            }
          );
      } else {
        this.$http
          .get(
            window.BASE_URL +
              "/company/listed/search-by-name-paging/" +
              value +
              "/?page=1"
          )
          .then(
            (res) => {
              if (res.data.companyList.length == 0)
                this.$Message.warning("建议前往一般企业模块分析");
              this.storeAll = res.data.companyList;
              this.total = res.data.total;
            },
            (res) => {
              // console.log("请求失败！");
            }
          );
      }
    },
    checkNumber(theObj) {
      let reg = /^([A-Z]|[0-9])+.?([A-Z]|[0-9])*$/;
      if (reg.test(theObj)) {
        return true;
      }
      return false;
    },
    add(id) {
      this.isAdd = !this.isAdd;
    },
  },
  computed: {
    ...mapState({
      checkoutSearchData: (state) => state.search.checkoutSearchData,
    }),
    ...mapGetters({
      keyDownNows: "keyDownNow",
    }),
    list: function() {
      return this.searchLists.filter((v) => {
        return (
          v.stockCode.match(this.searchText) || v.of.match(this.searchText)
        );
      });
    },
    listLength: function() {
      return this.list.length - 1;
    },
    hot: function() {
      let f = this.hotStand;
      if (0 <= f <= 20) {
        return 1;
      } else if (20 < f <= 40) {
        return 2;
      } else if (40 < f <= 60) {
        return 3;
      } else if (60 < f <= 80) {
        return 4;
      } else {
        return 5;
      }
    },
    hotArray: function() {
      this.array.length = this.hot;
      return this.array;
    },
    lessArray: function() {
      this.lessArrays.length = 5 - this.hot;
      return this.lessArrays;
    },
    cp: function() {
      if (this.isAdd == false) {
        return "收藏";
      } else {
        return "取消对比";
      }
    },
    contrast: function() {
      return "对比";
    },
    requestData() {
      // 在这里使用ajax或者fetch将对应页传过去获取数据即可
    },
  },
  watch: {
    currentPage: "requestData",
    pageNo: "",
  },
  ready() {
    this.requestData();
  },
};
</script>

<style lang="less" scoped>
.fontA {
  font-family: ArialMT;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}

.bottom-right {
  right: 20px;
  position: absolute;
  bottom: 0px;
}

.bgc_w {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 2rem;
}

.fz-14 {
  margin-left: 70px;
}

.search-list {
  left: 0;
  top: 49px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  z-index: 1;
}

.test {
  width: 48%;
  /* height: 2.7rem; */
  /* position: relative; */
  /* width: 750px; */
  /* height: 124px; */
  background: #ffffff;
  border-radius: 16px;
  /* border: 1px solid red; */
  margin: 10px;
}

.el-input__inner {
  margin: 0 10px;
}

.check-items {
  width: 130px;
  height: 48px;
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #333333;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 550;
  text-align: center;
}

.el-input__inner {
  border: none;
}

.el-select .el-input__inner {
  font-size: 16px;
  font-weight: 550;
}

.el-select .el-input .el-select__caret {
  height: 40px;
}

.vertical-img > img {
  vertical-align: baseline;
}

@media (max-width: 460px) {
  #inputText {
    font-size: 13px;
  }

  .search_s {
    font-size: 14px;
  }
}
</style>
